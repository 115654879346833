.about {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f9f9f9;
}


.abhyanga-reasons {
  text-align: center;
}

.abhyanga-reasons img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 400px;
}

@media screen and (max-width: 768px) {
  .reasons-grid {
    grid-template-columns: 1fr;
  }

  .abhyanga-reasons img {
    margin-top: 20px;
  }
}

.reasons-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.reason-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.reason-card:hover {
  transform: translateY(-10px);
}


@media screen and (max-width: 768px) {
  .reasons-grid {
    grid-template-columns: 1fr;
  }

  .abhyanga-reasons img {
    margin-top: 20px;
  }
}


.about h1 {
  text-align: center;
  color: #27ae60;
  margin-bottom: 40px;
  font-size: 2.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.about h2 {
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 1.8em;
  border-bottom: 2px solid #27ae60;
  padding-bottom: 10px;
}

.about h3 {
  color: #27ae60;
  margin-bottom: 15px;
  font-size: 1.5em;
}

.about p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.team-member {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.team-member-info {
  flex: 1;
  margin-right: 20px;
}

.team-member-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 300px;
}

.team-member-image img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .team-member {
    flex-direction: column;
  }

  .team-member-image {
    flex: 1;
  }
}

.info-section {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}

.left-column {
  flex: 2;
}

.right-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.abhyanga-reasons {
  background-color: #e8f5e9;
  padding: 40px;
  border-radius: 10px;
}

.reasons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.reason-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.reason-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.reason-card h3 {
  color: #27ae60;
  margin-bottom: 15px;
  font-size: 1.3em;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #e8f5e9;
}

.reason-card p {
  font-size: 0.95em;
  line-height: 1.6;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .team-member, .info-section {
    flex-direction: column;
  }

  .team-member-image {
    order: -1;
    margin-bottom: 20px;
  }

  .left-column, .right-column {
    width: 100%;
  }

  .reasons-grid {
    grid-template-columns: 1fr;
  }
}
