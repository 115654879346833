footer {
  background-color: #f8f5f0;
  color: #2c3e50;
  padding: 40px 0 20px;
  font-family: 'Roboto', sans-serif;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
}

.footer-section {
  flex-basis: 30%;
  margin-bottom: 20px;
}

.footer-section h3 {
  color: #27ae60;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.footer-section h4 {
  color: #27ae60;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.footer-links {
  list-style-type: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 8px;
}

.footer-links a {
  color: #2c3e50;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
  display: inline-block;
}

.footer-links a:hover {
  color: #27ae60;
  transform: translateX(5px);
}

.footer-section p {
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
}

.footer-bottom p {
  font-size: 0.8rem;
}

.social-links {
  margin-top: 15px;
}

.social-icon {
  display: inline-block;
  margin-right: 15px;
  color: #27ae60;
  font-size: 24px;
  transition: all 0.3s ease;
}

.social-icon:hover {
  color: #2ecc71;
  transform: scale(1.2);
}

address {
  font-style: normal;
}

@media (max-width: 768px) {
  .footer-section {
    flex-basis: 100%;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.footer-section {
  animation: fadeIn 0.5s ease-in-out;
}

.footer-section:nth-child(1) { animation-delay: 0.1s; }
.footer-section:nth-child(2) { animation-delay: 0.2s; }
.footer-section:nth-child(3) { animation-delay: 0.3s; }