.admin-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.admin-dashboard h1 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.appointments-section,
.subscribers-section,
.newsletter-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #27ae60;
  margin-bottom: 15px;
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.appointment-indicator {
  background-color: #27ae60;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.add-subscriber {
  display: flex;
  margin-bottom: 15px;
}

.add-subscriber input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
}

.add-subscriber button {
  padding: 8px 15px;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.subscriber-list {
  list-style-type: none;
  padding: 0;
}

.subscriber-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.subscriber-list button {
  padding: 5px 10px;
  margin-left: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.subscriber-list button:last-child {
  background-color: #e74c3c;
}

textarea {
  width: 100%;
  height: 150px;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.file-input {
  margin-bottom: 15px;
}

.file-input label {
  display: block;
  margin-bottom: 5px;
}

button {
  padding: 10px 20px;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #2ecc71;
}

@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .add-subscriber {
    flex-direction: column;
  }

  .add-subscriber input,
  .add-subscriber button {
    width: 100%;
    margin-bottom: 10px;
  }
}
