.password-reset {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.password-reset h2 {
  color: #27ae60;
  margin-bottom: 20px;
}

.password-reset form {
  display: flex;
  flex-direction: column;
}

.password-reset input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.password-reset button {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.password-reset button:hover {
  background-color: #2ecc71;
}

.success-message {
  color: #27ae60;
  margin-bottom: 10px;
}

.error-message {
  color: #e74c3c;
  margin-bottom: 10px;
}