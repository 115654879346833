.patient-resources {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.patient-resources h1 {
  text-align: center;
  color: #27ae60;
  margin-bottom: 40px;
  font-size: 2.5em;
}

.resource-card {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.patient-resources section {
  margin-bottom: 40px;
}

.patient-resources section:last-child {
  margin-bottom: 0;
}

.patient-resources h2 {
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 1.8em;
}

.patient-resources p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.resource-link, .contact-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #27ae60;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.resource-link:hover, .contact-button:hover {
  background-color: #2ecc71;
}

@media (max-width: 768px) {
  .resource-card {
    padding: 20px;
  }
}