.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #27ae60;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  text-align: center;
}

.button:hover {
  background-color: #2ecc71;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.button-secondary {
  background-color: #3498db;
}

.button-secondary:hover {
  background-color: #2980b9;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }
}