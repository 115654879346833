.services {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.services h1 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 40px;
}

.service-category {
  margin-bottom: 40px;
}

.service-category h2 {
  color: #2c3e50;
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

@media (max-width: 768px) {
  .service-grid {
    grid-template-columns: 1fr;
  }

  .card {
    height: auto;
  }
}

.service-image {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.card:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  transform: translateY(-5px);
}


.card h3 {
  color: #27ae60;
  margin-bottom: 10px;
}

.service-details {
  font-size: 0.9em;
  color: #7f8c8d;
  margin-bottom: 15px;
}

.service-description {
  font-size: 0.95em;
  color: #34495e;
  margin-bottom: 20px;
  flex-grow: 1;
}

.card-footer {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #27ae60;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  text-align: center;
}

.button:hover {
  background-color: #2ecc71;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

@media (max-width: 768px) {
  .service-grid {
    grid-template-columns: 1fr;
  }
}
