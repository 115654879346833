.cart {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.cart h1 {
  text-align: center;
  color: #27ae60;
  margin-bottom: 20px;
}

.empty-cart {
  text-align: center;
  margin-top: 40px;
}

.empty-cart p {
  font-size: 1.2em;
  color: #7f8c8d;
  margin-bottom: 20px;
}

.view-products-button {
  display: inline-block;
  background-color: #27ae60;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.view-products-button:hover {
  background-color: #2ecc71;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item {
  display: flex;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 20px;
}

.cart-item-details {
  flex-grow: 1;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.quantity-control button {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.remove-item {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.cart-summary {
  margin-top: 20px;
  text-align: right;
}

.place-order-button {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1em;
  margin-top: 10px;
}

.place-order-button:hover {
  background-color: #2ecc71;
}

.cart-summary form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.cart-summary input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}