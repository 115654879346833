header {
  background-color: #f8f5f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 100%;
  width: 50px;
  height: auto;
  margin-right: 10px;
}


@media (min-width: 768px) {
  .logo img {
    width: 70px;
  }

  .logo a {
    font-size: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .logo img {
    width: 90px;
  }

  .logo a {
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .logo img {
    width: 40px;
  }

  .logo a {
    font-size: 1.2rem;
  }
}


.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo a {
  text-decoration: none;
  color: #27ae60;
  font-size: 1.8rem;
  font-weight: 700;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0;
}

nav ul li {
  margin-left: 2rem;
}

nav ul li a {
  text-decoration: none;
  color: #2c3e50;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.3s ease;
  padding: 0.5rem 0;
  border-bottom: 2px solid transparent;
}

nav ul li a:hover {
  color: #27ae60;
  border-bottom: 2px solid #27ae60;
}

.header-icons {
  display: flex;
  align-items: center;
}

.search-icon,
.cart-icon,
.login-icon,
.user-menu {
  margin-left: 20px;
  cursor: pointer;
  font-size: 1.2em;
  color: #2c3e50;
  transition: color 0.3s ease;
}

.search-icon:hover,
.cart-icon:hover,
.login-icon:hover,
.user-menu:hover {
  color: #27ae60;
}

.cart-icon {
  position: relative;
}

.cart-count {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8rem;
}

.user-menu {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.user-menu:hover .dropdown-content {
  display: block;
}

.dropdown-content a,
.dropdown-content button {
  color: #2c3e50;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  cursor: pointer;
}

.dropdown-content a:hover,
.dropdown-content button:hover {
  background-color: #f1f1f1;
  color: #27ae60;
}

.search-form {
  background-color: #f8f5f0;
  padding: 1rem 20px;
  display: flex;
  justify-content: center;
}

.search-form input {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  width: 300px;
}

.search-form button {
  padding: 0.5rem 1rem;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.book-appointment-button {
  padding: 10px 20px;
  background-color: #27ae60;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 0.9em;
  transition: all 0.3s ease;
  margin-right: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.book-appointment-button:hover {
  background-color: #2ecc71;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: center;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 10px 0;
  }

  .header-icons {
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .book-appointment-button {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.hamburger-menu div {
  width: 2rem;
  height: 0.25rem;
  background: #2c3e50;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

@media (max-width: 768px) {
  .header-container {
    flex-wrap: wrap;
  }

  .hamburger-menu {
    display: flex;
  }

  nav {
    display: none;
    width: 100%;
    order: 3;
  }

  nav.show-mobile {
    display: block;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 10px 0;
  }

  .header-icons {
    order: 2;
  }

  .book-appointment-button {
    display: none;
  }

  .search-icon,
  .cart-icon,
  .login-icon,
  .user-menu {
    margin-left: 10px;
  }
}