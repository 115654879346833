#google-reviews {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.review {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.review p {
  margin: 5px 0;
}