.products {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.products h1 {
  text-align: center;
  color: #27ae60;
  margin-bottom: 20px;
}

.category-filter {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.category-filter button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.category-filter button:hover {
  background-color: #e0e0e0;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.product-item {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-item:hover {
  transform: translateY(-5px);
}

.product-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 15px;
}

.product-item h3 {
  margin-top: 0;
  color: #2c3e50;
  text-align: center;
}

.product-item p {
  margin: 10px 0;
  text-align: center;
}

.product-item button {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.product-item button:hover {
  background-color: #2ecc71;
}