.login-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.login-page h1 {
  color: #27ae60;
  margin-bottom: 30px;
}